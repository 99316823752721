<template>
  <UserIndex
      :create-text="'New Staff Member'"
      :can-create="
      !!$store.getters['entities/user-permissions/find']('create users')
    "
      :can-delete="
      !!$store.getters['entities/user-permissions/find']('delete users')
    "
      :with_permission="'be staff'"
      :without_roles="['learner', 'guardian','limited guardian']"
      :filter_by_without_roles="false"
      :default-role-filter="'all'"
      :tabs="[{ title: `Staff List` }]"
  ></UserIndex>
</template>
<script>
import UserIndex from "@/components/users/UserIndex";

export default {
  name: "Staff.index",
  components: { UserIndex },
};
</script>