<template>
  <div>
    <CampusHeader
        :activeTab="0"
        :can-all="true"
        :can-create="canCreate"
        :create-text="createText"
        :header-text="headerText"
        :header_tabs="[{ title: `${tabEntity} List` }]"
        :manage-campuses="true"
        :show-mobile="false"
        @start-creating="startCreatingUser"
    ></CampusHeader>
    <section class="section pt-4 box is-flex is-flex-direction-column is-justify-content-flex-start">
      <div class="is-flex-grow-1">
        <div class="columns">
          <div class="column">
            <b-field grouped>
              <b-field expanded horizontal label="Filter">
                <!--suppress HtmlUnknownAttribute -->

                <b-input
                    v-model="searchHold"
                    v-debounce:300ms="getFilteredUsers"
                    :loading="loadingFilter"
                    placeholder="Search"
                ></b-input>
              </b-field>
              <b-field v-if="rolesFilter">
                <b-select v-model="roleFilter" placeholder="Role">
                  <option v-if="roles_includes===null" value="all">All</option>
                  <option
                      v-for="role in roles"
                      :key="role.id"
                      :value="role.name"
                  >
                    {{ role.name }}
                  </option>
                </b-select>
              </b-field>

            </b-field>
          </div>
        </div>
        <b-table
            :backend-sorting="true"
            :bordered="false"
            :data="users"
            :default-sort="['first_name', 'asc']"
            :hoverable="true"
            :loading="loadingData"
            :striped="true"
            class="margin-top is-clickable"
            @click="onRowClick"
            @sort="onSort"
        >
          <template #empty>
            <div class="has-text-centered">No {{ tabEntity }}</div>
          </template>
          <b-table-column
              v-slot="props"
              field="id"
              label="ID"
              numeric
              sortable
              width="40"
          >{{ props.row.id }}
          </b-table-column>

          <b-table-column
              v-slot="props"
              field="first_name"
              label="First Name"
              sortable
          >{{ props.row.first_name }}
          </b-table-column>

          <b-table-column
              v-slot="props"
              field="last_name"
              label="Last Name"
              sortable
          >{{ props.row.last_name }}
          </b-table-column>
          <b-table-column v-slot="props" field="email" label="Email" sortable>{{
              props.row.email
            }}
          </b-table-column>
          <b-table-column
              v-if="showRoles"
              v-slot="props"
              class="is-capitalized"
              label="Roles"
          >{{ displayRoles(props.row.roles) }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              centered
              custom-key="actions"
              label=""

          >
            <div v-on:click.stop>
              <b-dropdown append-to-body aria-role="list" position="is-bottom-left">
                <template #trigger>
                  <b-icon
                      :icon="$tc('icons.more')"/>
                </template>
                <b-dropdown-item aria-role="listitem"
                                 @click="startDelete(props.row)">Delete
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-table-column>
        </b-table>
      </div>

      <b-pagination
          :current="page"
          :order="'is-centered'"
          :per-page="limit"
          :range-after="2"
          :range-before="2"
          :total="meta.total"
          aria-current-label="Current page"
          aria-next-label="Next page"
          aria-page-label="Page"
          aria-previous-label="Previous page"
          class="mt-4"
          v-on:change="setPage"
      >


      </b-pagination>
    </section>
  </div>
</template>

<script>
import User from "@/models/User";
import Role from "@/models/Role";
import CampusHeader from "@/components/panelled-dash/CampusHeader";
import UserCreateForm from "@/components/users/UserCreateForm";
import Campus from "@/models/Campus";
import UserProfile from "@/components/users/UserProfile";

export default {
  name: "User.index",
  components: {CampusHeader},
  data() {
    return {
      order_by: 'first_name',
      order_direction: 'asc',
      search: null,
      loadingFilter: false,
      userDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      roleFilter: this.defaultRoleFilter,
      loadingData: false,
      searchHold: null,
    };
  },
  props: {
    roles_includes: {
      type: String,
      default() {
        return null
      }
    },
    without_role: {
      type: String,
      default() {
        return "";
      },
    },
    with_permission: {
      type: String,
      default() {
        return null;
      },
    },
    without_roles: {
      type: Array,
      default() {
        return [];
      },
    },
    showRoles: {
      type: Boolean,
      default() {
        return true;
      },
    },
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    }, filter_by_without_roles: {
      type: Boolean,
      default() {
        return true;
      },
    },
    defaultRoleFilter: {
      type: String,
      required: true,
    }, defaultRole: {
      type: String,
      default() {
        return null
      }
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default() {
        return "Staff";
      },
    },
    createText: {
      type: String,
      default() {
        return "Staff";
      },
    },
    tabEntity: {
      type: String,
      default() {
        return "Staff";
      },
    },
    type: {
      type: String,
      default() {
        return "Staff Member";
      },
    },
    route: {
      type: String,
      default() {
        return "staff";
      },
    },
    rolesFilter: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  methods: {
    onRowClick(row) {
      this.$router.push(`/${this.route}/edit/${row.id}`)
    },
    onSort(field, order) {

      this.order_by = field;
      this.order_direction = order;
    },


    startDelete(user) {
      this.$buefy.dialog.confirm({
        title: `Deleting ${this.type}`,
        confirmText: `Delete ${this.type}`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this ${this.type}?`,
        onConfirm: () =>
            User.Delete(user.id)
                .then(() => {
                  this.$buefy.snackbar.open(`${this.type} deleted!`);
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    startCreatingUser() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
          campuses: this.campuses,
          type: this.type,
          canCreate: this.canCreate,
          defaultRole: this.defaultRole
        },
        component: UserCreateForm,
        hasModalCard: true,
        trapFocus: true,
        events: {
          "user-created": (user) => {
            this.viewUser(user);
          },
        },
      });
    },
    displayRoles(roles) {
      let blank = "";
      roles.forEach((element, index) => {
        blank += Role.query().whereId(element.id).first().name;
        if (index < roles.length - 1) {
          blank += ", ";
        }
      });
      return blank;
    },
    viewUser(user) {
      this.$cookies.set(
          `${this.type}_filters`,
          {
            page: this.page,
            search: this.search,
            role: this.roleFilter,
            order_by: this.order_by,
            order_direction: this.order_direction

          },
          "1d"
      );
      if (this.use_modal) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            inModal: true,
            tabs: [{title: "User Details", role: "details"}],
            proppedUser: user,
          },
          component: UserProfile,
          fullScreen: true,
          trapFocus: true,
        });
      } else {
        this.$router.push(`/${this.route}/edit/${user.id}`);
      }
    },
    getFilteredUsers(text) {
      this.search = text;
    },
    setPage(pageNumber, initial = false) {
      this.loadingData = true;
      this.userDataHold = this.users;
      User.deleteAll();
      this.page = pageNumber;
      User.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          this.filters,
          ["roles"]
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta;
            this.loadingData = false;
            this.userDataHold = null;
            if (!initial) {
              this.$cookies.set(
                  `${this.type}_filters`,
                  {
                    page: this.page,
                    search: this.search,
                    role: this.roleFilter,
                    order_by: this.order_by,
                    order_direction: this.order_direction
                  },
                  "1d"
              );
            }
          }
      );
    },
  },
  watch: {
    filters() {
      this.setPage(this.page);
    },
  },
  computed: {
    campuses() {
      return Campus.query().get();
    },
    filters() {
      return {
        ...(this.order_direction
            ? {
              order_direction: this.order_direction,
            }
            : {}), ...(this.order_by
            ? {
              order_by: this.order_by,
            }
            : {}),
        ...(this.roleFilter !== "all"
            ? {
              user_role: this.roleFilter,
            }
            : {}),
        ...(this.search
            ? {
              search: this.search,
            }
            : {}),
        ...(this.without_role
            ? {
              without_role: this.without_role,
            }
            : {}),
        ...(this.without_roles && this.filter_by_without_roles === true
            ? {
              without_roles: this.without_roles,
            }
            : {}), ...(this.with_permission
            ? {
              user_permission: this.with_permission,
            }
            : {}),
        ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
            ? {campus_id: this.$store.state.campus.selected_campus_id}
            : {}),
      };
    },
    users() {
      if (this.userDataHold === null) {
        return User.query().with("roles").orderBy(this.order_by, this.order_direction).get();
      } else {
        return this.userDataHold;
      }
    },
    roles() {
      if (this.roles_includes === null) {
        return Role.query()
            .where((role) => {
              return !this.without_roles.includes(role.name);
            })
            .get();
      }
      return Role.query()
          .where((role) => {
            return role.name.includes(this.roles_includes);
          })
          .get();
    },
    edit_users() {
      return this.$store.getters["entities/user-permissions/find"](
          "edit users"
      );
    },
  },
  mounted() {
    this.$store.dispatch("loader/show");
    if (this.$cookies.isKey(`${this.type}_filters`)) {
      let filters = this.$cookies.get(`${this.type}_filters`);
      this.roleFilter = filters.role;
      this.page = filters.page;
      this.search = filters.search;
      this.searchHold = filters.search;
      this.order_by = filters.order_by
      this.order_direction = filters.order_direction
      this.$cookies.remove(`${this.type}_filters`);
    }
    Role.FetchAll({page: 1, limit: 999}).then(() => {
      this.$store.dispatch("loader/hide");
      this.setPage(this.page, true);
    }).catch(
        err => {
          this.$store.dispatch("loader/hide");

          if (err.response.status === 422) {
            this.handleError(err)
          } else {
            this.handleError(err)
          }
        }
    );
  },
};
</script>
